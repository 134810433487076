import $ from 'jquery'
import { attachDynamicEvent, whenSelectorPresent } from '~/common/helpers/dom'

const listAutocorrectorSelector = '.signup-form__field input[type=email]'
async function initializeListAutocorrector() {
  await import('./autocorrect')
  $(document).initializeEmailAutoCorrector({
    inputSelector: listAutocorrectorSelector,
    correctionTextsDataContainerSelector: '.signup-form',
    autoCorrectOnSubmit: true,
  })
}

whenSelectorPresent(listAutocorrectorSelector).then(initializeListAutocorrector)
